import React from 'react'

import { FaqSection, Title } from './styles'
import Container from '../../UI/Container'
import Accordion from '../../UI/Accordion'

const FAQauthority = ({ questions, title }) => {
  return (
    <FaqSection>
      <Container>
        <Title>{title}</Title>
        {questions.map(({ title, content }, i) => (
          <Accordion key={i} title={title} mode="dark">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Accordion>
        ))}
      </Container>
    </FaqSection>
  )
}

export default FAQauthority
