import styled, { css } from 'styled-components'

export const WrapForm = styled.div`
  position: relative;
  border-radius: 20px;
  background: linear-gradient(90deg, #0076b5 0%, #0e3152 100%);
  width: 820px;
  height: auto;
  margin: 70px 0;
  padding: 45px 67px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 830px) {
    flex-direction: column;
    width: 90vw;
  }

  @media screen and (max-width: 630px) {
    padding: 45px 24px;
  }
`
export const TextBlock = styled.div`
  width: 267px;
  height: auto;

  @media only screen and (max-width: 830px) {
    width: 100%;
    height: auto;
    text-align: center;
    padding: 0 30px;
  }

  @media screen and (max-width: 430px) {
  }
`
export const FormSent = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: left;
  line-height: 130%;
  margin-left: 20px;
  @media only screen and (max-width: 830px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const ErrorMsg = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: left;
  line-height: 130%;
  margin-left: 20px;
  @media only screen and (max-width: 830px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const WrapButtonAndMsg = styled.div`
  position: relative;

  display: flex;

  @media only screen and (max-width: 830px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const FormBlock = styled.form`
  width: 388px;
  height: auto;
  @media only screen and (max-width: 830px) {
    width: 100%;
    margin-top: 0px;
  }

  @media screen and (max-width: 430px) {
  }
`
export const Input = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  select:invalid {
    color: #c1c1c1 !important;
  }
  input,
  select {
    border-radius: 10px;
    border: 1px solid #ebebeb;
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
    height: 40px;
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;
    color: black !important;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
  }
  input::placeholder {
    color: #c1c1c1;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 49.98px; /* 312.375% */
  }

  input:active {
    color: red;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 49.98px; /* 312.375% */
  }

  input::-ms-input-placeholder {
    color: #c1c1c1;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 49.98px; /* 312.375% */
  }
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const Button = styled.button`
  width: 117px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  background: #a9cf3a;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const Title = styled.div`
  color: #fff;
  font-family: Barlow;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 49.98px; /* 124.95% */
  margin-bottom: 40px;
  @media only screen and (max-width: 830px) {
    display: none;
  }

  @media screen and (max-width: 430px) {
  }
`
export const FormTitle = styled.div`
  color: #fff;
  font-family: Barlow;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 49.98px; /* 124.95% */
  margin-bottom: 20px;
  @media only screen and (max-width: 830px) {
    text-align: center;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 430px) {
  }
`
export const Text = styled.div`
  color: #fff;
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 49.98px; /* 208.25% */

  @media only screen and (max-width: 830px) {
    display: none;
  }

  @media screen and (max-width: 430px) {
  }
`
export const SendingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 0.47rem auto;

  span {
    display: inline-flex;
    width: 8px;
    height: 8px;
    margin: 0 0.3rem;
    background-color: white;
    border-radius: 50%;
    animation: bounce 0.4s linear infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes bounce {
    20% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`
