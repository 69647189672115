import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const WrapHPMS = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  box-sizing: border-box;
  max-width: 1860px;
  min-width: 1200px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    min-width: unset;
    width: 100vw;
  }
  @media only screen and (max-width: 425px) {
  }
`

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Header from 'components/Header'
import Footer from 'components/Footer'

import Logo from 'components/Logo'
import Button from 'components/Button'

import NotFoundImg from 'images/not-found-404.png'
import Banner from '../../components/hotel-property-management-system-en/banner'
import Content from '../../components/hotel-property-management-system-en/content'
import FAQauthority from '../../components/hotel-property-management-system-en/FAQauthority'
import GetStartedCta from '../../components/GetStartedCTA'
import schema from '../../components/hotel-property-management-system-en/schema.json'

const faqData = [
  {
    content:
      'PMS stands for a property management system, and it is used by hotel staff and management to run the entire property from pre-booking to check-out and beyond. Click here to find out more about hotel PMS.',
    title: 'What is a Hotel PMS?'
  },
  {
    content:
      'Legacy PMS is outdated software with an old-looking user interface. Legacy software usually costs more and is harder to implement. Customizations are expensive and typically take many IT hours. Cloud-based systems are the opposite. Cloud is more flexible, easy to implement, and less expensive. Click here to find out more about cloud-based PMS.',
    title: 'What is the difference between legacy and Cloud-Based PMS?'
  },
  {
    content:
      'Rentlio is a modern, cloud-based property management system made for hotels of all sizes. More than 1,000 customers in over 40 countries are managing their properties using Rentlio PMS. Request Demo',
    title: 'Why Rentlio PMS?'
  }
]
const cta_mobile_apps = {
  title: 'READY TO GET STARTED?',
  description:
    "Create your account and invite your team. You'll save time and earn more. Give us a try; you won't regret it!"
}

const HPMS = ({ location }) => {
  return (
    <Layout locale="en">
      <SEO
        title="#1 Hotel PMS l Property Management System"
        description="PMS stands for a property management system, and hotel staff and management use it to run the entire property from pre-booking to check-out and beyond."
        pathname={location.pathname}
        lang="en"
        location={location}
        schemaMarkup={schema}
      />
      <Header mode="dark" locale="en" color={'#FFFFFF'} opacity={0.3} location={location} hasTranslation="false" />
      <WrapHPMS>
        <Banner />
        <Content locale="en" />
      </WrapHPMS>
      <FAQauthority questions={faqData} title="FAQ" authority />
      <GetStartedCta data={cta_mobile_apps} locale="en" location={location} />

      <Footer locale="en" location={location} />
    </Layout>
  )
}

export default HPMS
