import styled from 'styled-components'

export const FaqSection = styled.section`
  padding: 5rem 0;
  height: auto;
  /* background: #0e3151; */
  margin-top: 40px;
  margin-bottom: 50px;
  @media screen and (max-width: 425px) {
    padding: 2rem 0;
    margin-bottom: 80px;
  }
`
export const Title = styled.h3`
  color: #0d3151;
  font-family: 'Barlow';
  font-size: 3.78rem;
  font-weight: 800;
  line-height: 3.57rem;
  text-align: left;
  margin-bottom: 7rem;
  margin-left: 40px;

  @media screen and (max-width: 425px) {
    font-size: 2.5rem;
    line-height: 2.8rem;
    margin-bottom: 3rem;
  }
`
