import React, { useState, useEffect, useRef } from 'react'

import {
  WrapForm,
  TextBlock,
  FormBlock,
  Title,
  FormTitle,
  Text,
  Input,
  Button,
  FormSent,
  WrapButtonAndMsg,
  ErrorMsg,
  SendingWrapper
} from './styles'
import Img from 'gatsby-image'
import qs from 'qs'
import fetch from 'isomorphic-fetch'
import 'es6-promise/auto'

const Form = ({ locale }) => {
  const [state, setState] = useState({ name: '', email: '', propertyType: '', phone: '' })

  const [msg, setMsg] = useState(false)
  const [errors, setErrors] = useState({ name: '', email: '', message: '' })
  const [sending, setSending] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = {
      name: state.name,
      email: state.email,
      message: `phone:${state.phone}, property type:${state.propertyType}`
    }

    setSending(true)
    fetch(`${process.env.APP_BASE_URL}${locale}/contact-form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: qs.stringify(formData)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'ERROR') {
          data.errors.map((error) => {
            Object.keys(errors).map((key) => {
              setErrors((errors) => ({ ...errors, [error.filed]: error.message }))
            })
            return error
          })
          setSending(false)
          setTimeout(() => {
            setMsg(false)
            setErrors({ name: '', email: '', message: '' })
          }, 6000)
          return
        }

        // handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'contact_form_submitted', 'contact_form', false, true)
        setMsg(true)
        setState({ name: '', email: '', propertyType: '', phone: '' })
        setSending(false)
        setTimeout(() => {
          setMsg(false)
        }, 6000)
      })
      .catch((error) => console.log('Error: ', error))
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }
  const Sending = () => {
    return (
      <SendingWrapper>
        <span />
        <span />
        <span />
      </SendingWrapper>
    )
  }

  return (
    <WrapForm>
      <TextBlock>
        <Title>{locale === 'en' ? 'Optimize operations for' : 'Optimizacija procesa za'}</Title>
        <Text>{locale === 'en' ? 'Hotels' : 'Hotele'}</Text>
        <Text>{locale === 'en' ? 'Vacation Rentals' : 'Iznajmljivače'}</Text>
        <Text>{locale === 'en' ? 'Property managers' : 'Property managere'}</Text>
      </TextBlock>
      <FormBlock>
        <FormTitle>{locale === 'en' ? 'Get in Touch' : 'Kontaktirajte nas'}</FormTitle>
        <Input>
          <input
            type="text"
            placeholder={locale === 'en' ? 'Full name' : 'Ime i Prezime'}
            name="name"
            value={state.name}
            onChange={handleChange}
            required
          />
        </Input>
        <Input>
          <input type="email" placeholder="Email" name="email" value={state.email} onChange={handleChange} required />
        </Input>
        <Input>
          <input
            type="tel"
            placeholder={locale === 'en' ? 'Phone number' : 'Broj mobitela'}
            name="phone"
            value={state.phone}
            onChange={handleChange}
            required
          />
        </Input>
        <Input>
          <select value={state.propertyType} name="propertyType" onChange={handleChange} required>
            <option value="" disabled>
              {locale === 'en' ? 'Property type' : 'Tip objekta'}
            </option>
            <option value="Hotel">Hotel</option>
            <option value="Iznajmljivač">{locale === 'en' ? 'Vacation Rental' : 'Iznajmljivač'}</option>
            <option value="Property manager">Property manager</option>
          </select>
        </Input>
        <WrapButtonAndMsg>
          <Button onClick={(e) => handleSubmit(e)}>
            {sending ? <Sending /> : locale === 'en' ? 'SEND' : 'POŠALJI'}
          </Button>
          {msg && (
            <FormSent>
              {locale === 'en' ? (
                <span>Thank you for your inquiry, we will contact you within 24 hours.</span>
              ) : (
                <span>Hvala na poslanom upitu, kontaktirati ćemo vas unutar 24 sata.</span>
              )}
            </FormSent>
          )}
          {errors.message && <ErrorMsg>{errors.message}</ErrorMsg>}
        </WrapButtonAndMsg>
      </FormBlock>
    </WrapForm>
  )
}

export default Form
