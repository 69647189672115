import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { LocaleConsumer } from '../Layout'

import {
  CtaSection,
  CtaWrapper,
  ColumnLeft,
  ColumnRight,
  Title,
  Subtitle,
  FormWrapper,
  List,
  SubmitButton
} from './styles'

import Container from '../UI/Container'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const GetStartedCta = ({ data, locale, location }) => {
  const { title, description } = data
  const [email, setEmail] = useState('')

  const mixpanel = useMixpanel()

  const linkPath = locale === 'hr' ? `registracija${location.search}` : `${locale}/sign-up${location.search}`

  const handleSubmit = (e) => {
    e.preventDefault()

    if (email === '') return
    let link = `https://app.rentl.io/${linkPath}&email=${email}`
    handleTrackedRentlioWebappClick(
      e,
      link,
      location.search,
      mixpanel,
      'try_free_button_clicked',
      'cta_box_bottom',
      true,
      true,
      true
    )
    setEmail('')
  }

  const handleChange = (e) => {
    const { value } = e.target
    setEmail(value.trim())
  }
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <CtaSection>
          <Container addMargin style={{ maxWidth: 'unset' }}>
            <CtaWrapper>
              <ColumnLeft>
                <Title>{title}</Title>
                <Subtitle>{description}</Subtitle>
                <FormWrapper>
                  <SubmitButton href="https://app.rentl.io/en/sign-up">{i18n.tryFree}</SubmitButton>
                </FormWrapper>
              </ColumnLeft>
              <ColumnRight>
                <List>
                  <li>{i18n.benefits.free}</li>
                  <li>{i18n.benefits.easy}</li>
                  <li>{i18n.benefits.cancel}</li>
                  <li>{i18n.benefits.card}</li>
                </List>
              </ColumnRight>
            </CtaWrapper>
          </Container>
        </CtaSection>
      )}
    </LocaleConsumer>
  )
}

GetStartedCta.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  })
}

GetStartedCta.defaultProps = {
  location: {
    search: ''
  }
}

export default GetStartedCta
