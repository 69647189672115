import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { AccordionWrapper, AccordionTitle, AccordionContent } from './styles'

const Accordion = ({ title, children, mode }) => {
  const [expand, setExpandState] = useState(false)
  const [contentHeight, setContentHeight] = useState('0px')

  const accordionContent = useRef(null)

  const toogleAccordion = () => {
    setExpandState(!expand)
    setContentHeight(expand ? '0px' : `${accordionContent.current.scrollHeight}px`)
  }

  return (
    <AccordionWrapper onClick={toogleAccordion} isExpanded={expand} mode={mode}>
      <AccordionTitle mode={mode}>{title}</AccordionTitle>
      <AccordionContent ref={accordionContent} height={contentHeight} mode={mode}>
        {children}
      </AccordionContent>
    </AccordionWrapper>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}

export default Accordion
