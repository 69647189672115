import React, { useState, useEffect, useRef } from 'react'

import {
  WrapContent,
  MiddleTextContent,
  IntroText,
  GreyBlock,
  H2,
  H3,
  P,
  PUL,
  PMSsustavIMG,
  FullWidthImg,
  ImgTitle,
  MiddleContent,
  GradientText,
  SideText,
  ButtonCTA,
  UnderButtonText,
  LeftColumn,
  TopText,
  LeftLink,
  BlueLine,
  WrapLeftContent
} from './styles'
import Img from 'gatsby-image'

import PMSsustav from '../../../assets/images/PMSsustav.png'
import Kokpit from '../../../assets/images/MainDashboard.png'
import Kalendar from '../../../assets/images/Kalendar.png'
import Statistika from '../../../assets/images/Statistika.png'
import MobileApps from '../../../assets/images/MobileApps.png'
import RadarGroup from '../../../assets/images/RadarGroup.png'
import BookingAndReservations from '../../../assets/images/b&r.png'
import ReportingDashboard from '../../../assets/images/reportingDashboard.png'
import Biling from '../../../assets/images/biling.png'
import Integrations from '../../../assets/images/integrationsAuthority.png'
import Form from '../../AuthorityForm'

const links = [
  {
    id: 1,
    title: 'What is a Hotel PMS?'
  },
  {
    id: 2,
    title: '5 Reasons Why You Need a Cloud-Based Hotel PMS System'
  },
  {
    id: 3,
    title: 'Key Features of Using a Hotel Property Management System'
  },
  {
    id: 4,
    title: 'Benefits of Using a Modern Hotel Property Management System'
  },
  {
    id: 5,
    title: 'Why is it Important to use Integrated PMS Software, Channel Manager, and Web Booking Engine?'
  },
  {
    id: 6,
    title: 'What is the difference between On-Prem and Cloud-based hotel PMS Systems?'
  },
  {
    id: 7,
    title: 'Investing in Hotel Property Management Software: Price, Implementation, Onboarding, and Support'
  },
  {
    id: 8,
    title: 'How to Choose Property Management System for your Hotel Business?'
  },
  {
    id: 9,
    title: 'Why Rentlio PMS?'
  }
]

const Content = ({}) => {
  useEffect(() => {
    var links = document.querySelectorAll('.link')
    var sections = document.querySelectorAll('.sections')
    window.onscroll = function () {
      var current = 'home'
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
      links.forEach((item) => {
        item.classList.remove('active')
        if (item.href.slice(-1) === current) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    }
  }, [])
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://app.rentl.io/en/sign-up'
  }

  return (
    <WrapContent>
      <LeftColumn>
        <WrapLeftContent>
          <BlueLine />
          <TopText>In-page topics </TopText>
          {links.map((link) => (
            <LeftLink key={link.id}>
              <a className="link" href={`#${link.id}`}>
                {link.title}
              </a>
            </LeftLink>
          ))}
        </WrapLeftContent>
      </LeftColumn>
      <MiddleTextContent>
        <H2 id="1" className="sections">
          What is a Hotel PMS?
        </H2>
        <P>
          A <a href="https://rentl.io/property-management-system"> Hotel Property Management System</a> is a complete
          software solution designed to assist hoteliers in efficiently managing and coordinating various aspects of
          their property and guest services. It serves as the central nervous system of the hotel, integrating and
          streamlining numerous operations, from front-desk tasks to back-office management and guest interactions. With
          the advent of the post-COVID digital transformation, the importance of a modern and fully digital PMS has
          become even more pronounced.
          <br />
          <br />
          In the modern hospitality landscape, guests increasingly expect a seamless and fully digital experience even
          before arriving at the hotel. They seek convenience, personalization, and efficiency throughout their stay,
          from the initial reservation to check-out and beyond. As a result, hotels must adapt to these evolving guest
          expectations by embracing technology-driven solutions like cloud-based and digitally enabled PMS systems.
          <br />
          <br />
          The contrast between legacy PMS systems and modern cloud-based PMS is significant. Legacy PMS systems
          developed more than two decades ago, often lack the agility, flexibility, and user-friendliness required to
          meet the demands of the current hospitality industry. These outdated systems can be challenging to customize
          and expensive to maintain. As a result, hotels using legacy PMS systems may find themselves falling behind in
          providing the level of service expected by modern travelers.
          <br />
          <br />
          On the other hand,
          <a href="https://rentl.io/property-management-system"> modern cloud-based Hotel PMS platforms</a> offer many
          benefits that align with guests' digital expectations and hoteliers' needs. Below, you will find the key hotel
          PMS features and functionalities and how they can help run a modern hotel business.
        </P>
        <GreyBlock id="2" className="sections">
          <H2>5 Reasons Why You Need a Cloud-Based Hotel PMS System</H2>
          <H3>Reduce Cost and Utilize Free Automatic Upgrades</H3>
          <P>
            Legacy software providers have multiple costs, such as hardware and storage, high maintenance costs,
            expensive customizations, complex integrations, and much more. Any change or update to the legacy software
            takes a long time and costs a lot of money.
          </P>
          <P>
            On the other hand, modern cloud-based PMS software is known for fast implementation, minimal IT and
            maintenance costs, open API integrations, and free and automatic product updates.
          </P>
          <P>
            Most cloud-based providers offer subscription-based pricing models without hidden fees and costs. The
            subscription-based model is more affordable for hoteliers, especially considering the additional costs of
            implementing and maintaining a legacy PMS system.
          </P>
          <H3>Automate Tasks and Processes</H3>
          <P>Are you still using Excel spreadsheets to manage your hotel business?</P>
          <P>
            If the answer is ‘’YES’’ you are not ready to take part in today’s fast-paced digital environment in which
            hoteliers are competing to attract guests, retain quality stuff, sell more rooms online, and improve the
            guest experience.
          </P>
          <P>
            Cloud-based hotel PMS systems can help automate all areas of your hotel business by offering online
            check-in, mobile ID and passport scanner, automatic pricing, availability sync with all OTAs, guest
            experience, and more.
          </P>
          <P>
            Hotel technology is rapidly evolving, and hoteliers need to understand their needs and challenges to find
            the right technology.
          </P>

          <H3>Access PMS Anytime and Across All Devices</H3>
          <P>
            When selecting a property management system, ensure you can access it across all devices, including
            desktops, tablets, and mobile phones. This allows you to always stay up to date with everything happening
            inside the hotel business.
          </P>
          <P>
            Modern cloud-based PMS are flexible and easily accessible anytime across all devices. Regardless if you want
            to check your reservations, need to book a room for a special guest, or change and push the latest pricing
            across all channels – you should be able to do it all directly from your smartphone.
          </P>
          <P>
            <a href="https://rentl.io/en/mobile-apps">
              Download a mobile app for IOS and Android for free and discover how you can manage your property from a
              mobile phone!
            </a>
          </P>
          <H3>Make Data-Driven Decisions</H3>
          <P>
            Data and statistics are very important before making strategic decisions. A complete property management
            system aims to simplify and automate hotel operations, track customer data, and help make educational
            decisions.
          </P>
          <P>
            The data and statistics, such as occupancy rate, guest nationalities, number of nights, and channels, are
            critical to double down your sales and marketing activities to attract more customers.
          </P>
          <P>
            The data can help you achieve your goals by switching focus on the things generating 99% of the profit, not
            the 1%. Don’t forget that selling beds and food generates 95% of the hotel's revenue.
          </P>
          <H3>Drive Direct and Online Bookings</H3>
          <P>
            Everything is happening online nowadays. Hotels are relying on online channels to generate bookings and
            revenue. There are two main ways to book a room online:
          </P>
          <PUL>
            <ol>
              <li> OTA channels</li>
              <li> Direct bookings through the hotel website.</li>
            </ol>
          </PUL>
          <P>
            OTA channels such as booking.com. Airbnb and Expedia are the most common online channels. A modern PMS with
            integrated channel manager software helps push pricing and availability updates to all channels
            simultaneously. Integrating hotel PMS and Channel Manager eliminates overbookings and ensures that all
            channels are in sync anytime.
          </P>
          <P>
            Direct bookings come through the hotel website. You need to install a widget on the hotel website called the
            Web Booking Engine (WBE). An integrated Hotel PMS and WBE allow you to receive direct bookings from your own
            website into the property management system. Direct bookings through hotel websites are very popular
            nowadays because hoteliers avoid paying high OTA fees and keep more money by generating direct bookings.
          </P>
          <P>
            <a href="https://rentl.io/en/booking-engine">
              Find out how hotels use Rentlio’s integrated Web Booking Engine to help increase direct bookings.
            </a>
          </P>
          {/* <PMSsustavIMG>
            <img src={PMSsustav}></img>
          </PMSsustavIMG> */}
        </GreyBlock>
        <H2 id="3" className="sections">
          Key Features of Using a Hotel Property Management System
        </H2>
        <P>
          Before selecting a property management system, it is important to understand the difference between features
          and functionalities. Think of a feature as a set of specific tools you would use to complete a task.
          Functionalities, on the other hand, are what the product can do to solve your unique business problem.
        </P>
        <P>
          Since every hotel has unique requirements, it is important to find a technology partner who can help support
          you through the entire process. Discover the key features of Rentlio PMS and how they can help you modernize
          your hotel business.
        </P>
        <H3>Main Dashboard</H3>
        <P>
          The main dashboard is an overview of all reservations inside the PMS system. A dashboard shows all arrivals,
          stays, and departures on a specific day. You can add filters and customize the dashboard based on your needs.
          The elastic search bar on the top makes finding a reservation and guest easy anytime.
        </P>
        <FullWidthImg>
          <img src={Kokpit} />
        </FullWidthImg>
        <ImgTitle>Main Dashboard</ImgTitle>
        <H3>Calendar</H3>
        <P>
          Ninety-day calendar view makes viewing all reservations, availability, and prices for each room easy. You can
          create as many room types as you want and manage prices and availability anytime. All the changes you make
          inside the PMS will automatically sync with your website and all OTA channels. A simple drag-and-select
          feature makes it easy to manage a calendar and book a room anytime. Multiple view options allow you to see all
          princes and make changes as you wish.
        </P>
        <FullWidthImg>
          <img src={Kalendar} />
        </FullWidthImg>
        <ImgTitle>Rentlio Interactive Calendar</ImgTitle>
        <H3>Booking and Reservations</H3>
        <P>
          Booking and Reservations module allows you to see all guest information in one place, including check-in and
          check-out date, number of people, total rate, and payment status.
        </P>{' '}
        <P>
          The module makes it easy to create single or group reservations and edit and update existing reservations
          directly inside the PMS. Option to view the payment balance and add additional charges to the account. Create
          folios and split bills based on customer wishes.
        </P>
        <FullWidthImg>
          <img src={BookingAndReservations} />
        </FullWidthImg>
        <ImgTitle>Rentlio Bookings and Reservtions</ImgTitle>
        <H3>Reporting and Analytics</H3>
        <P>
          Analyze and monitor earnings month over month. Overview on occupancy rate, reservations per country,
          reservations per channel, price report, and more.
        </P>{' '}
        <P>
          Customize reports based on your needs and wishes. Rentlio offers more than 50 reports that will tell you
          everything you need to know about your business and help you make strategic decisions.
        </P>
        <FullWidthImg>
          <img src={ReportingDashboard} />
        </FullWidthImg>
        <ImgTitle>Rentlio Reporting Dashboard</ImgTitle>
        <H3>Billing and Invoicing</H3>
        <P>
          Overview of all invoices, payments, and cancelations in one place. You can check the outstanding balance and
          payment methods per reservation. Option to edit and customize receipts based on guest’s wishes.
        </P>
        <FullWidthImg>
          <img src={Biling} />
        </FullWidthImg>
        <ImgTitle>Rentlio Billing and Invoicing</ImgTitle>
        <H3>Integrations</H3>
        <P>
          In addition to an integrated channel manager and web booking engine, Rentlio offers many integrations that can
          be implemented with a few clicks. Open API makes it easy for hoteliers to integrate and connect with their
          favorite apps.
        </P>
        <FullWidthImg>
          <img src={Integrations} />
        </FullWidthImg>
        <ImgTitle>Rentlio Integrations</ImgTitle>
        <H3>Mobile App</H3>
        <P>
          Option to download and use the mobile app, which offers identical features as you see on the desktop version.
          The mobile app allows you to monitor everything happening inside your hotel business. You can make and manage
          reservations, change prices and availability per room, scan passports and IDs to check-in guests, and more.
        </P>
        <P>
          <a href="https://rentl.io/en/mobile-apps">
            Download Rentlio’s Mobile app for Android and IOS for free and explore its potential.
          </a>
        </P>
        <FullWidthImg>
          <img src={MobileApps} />
        </FullWidthImg>
        <ImgTitle>Rentlio Mobile App</ImgTitle>
        <GreyBlock>
          <H2 id="4" className="sections">
            Benefits of Using a Modern Hotel Property Management System
          </H2>
          <P>
            Over 35% of hoteliers still use pen, paper, and Excel spreadsheets to manage their properties. Imagine how
            your guests feel when they arrive at your property and wait until the front desk manager writes down ID
            details on paper. What do they think? Which century do we live in?
          </P>
          <P>
            A modern hotel management system can help automate recurring tasks such as updating room rates and
            availability, sending out custom emails for pre-arrival and post-stay, invoicing guests, and more.
          </P>
          <P>
            Integrated and easy-to-manage channel manager allows you to take bookings from various OTA channels and view
            them directly inside the hotel PMS. With a modern PMS system, your staff spends less time on administration
            and more time making guests welcome and happy.
          </P>
          <P>
            There are many cloud-based PMS providers available today. The cost of a PMS solution is no longer high and
            is difficult to implement for smaller cost-sensitive properties. Calculate Rentlio’s pricing and determine
            how much you can save by implementing the Rentlio property management system.
          </P>
        </GreyBlock>
        <H2 id="5" className="sections">
          Why is it Important to use Integrated PMS, Channel Manager, and Web Booking Engine?
        </H2>
        <P>
          Most PMS providers offer back-office PMS without a Web Booking Engine and Channel Manager. In this case, you
          get a basic property management system for which you need multiple integrations to manage your hotel business
          and receive online reservations. Integrations such as Booking Engine and Channel Manager can be very
          expensive, adding additional expense to your overall hotel business.
        </P>
        <P>
          At Rentlio, we believe Channel Manager and Web Booking Engine are the core tech stack for each hotel and
          should, therefore, be integrated and included in the PMS package. Rentlio’s integrated PMS, Booking Engine,
          and Channel Manager are always synchronized, so you don’t need to worry about having different prices and
          availabilities across OTA channels, eliminating overbookings.
        </P>
        <P>
          With Rentlio’s integrated PMS, Booking Engine, and Channel Manager, the hoteliers have only one company and
          one support team to call if something goes wrong.
        </P>
        <GreyBlock>
          <H2 id="6" className="sections">
            What is the difference between On-Prem and Cloud-based hotel PMS Systems?
          </H2>
          <P>
            Hundreds of PMS providers are in the market, and hoteliers must be very selective when choosing the right
            solution. Before you even start looking for a PMS, you need to know what you are looking for – a Legacy
            On-Prem or a Cloud-Based PMS Solution.
          </P>
          <H3>On-Prem PMS</H3>
          <P>
            The first On-Premisses PMS hotel management system was developed back in the late 90s to solve simple front
            desk tasks such as check-in and check-out. Over the years, legacy solutions somewhat evolved and adjusted to
            the new trends, but most of the features and UI, and UX stayed as it was back in the 90s.
          </P>{' '}
          <P>
            On top of that, the legacy on-prem solutions are complex solutions built on old technologies, which makes
            them difficult to customize. High implementation and maintenance costs, outdated user interfaces, and lack
            of integrations switched hoteliers' focus to exploring different options.
          </P>
          <H3>Cloud-Based PMS</H3>
          <P>
            Cloud-based PMS is a modern solution built on top of the latest technologies. Its clean, feature-rich UI and
            UX make it the right fit for any hotel business. The flexibility to customize based on your needs and
            integrate with your favorite integration partner makes it the preferred PMS option.
          </P>
          <P>
            <a href="https://rentl.io/en">
              Click here to learn how Rentlio’s PMS, Channel Manager, and Web Booking Engine help hoteliers manage their
              properties worldwide.
            </a>
          </P>
        </GreyBlock>
        <Form locale="en" />
        <H2 id="7" className="sections">
          Investing in Hotel Property Management System: Price, Implementation, Onboarding, and Support
        </H2>
        <H3>Price</H3>
        <P>
          The price of a PMS depends on several factors, such as the number of units, the number of integrations, the
          complexity setup, the need for a booking engine, the support level, and many others.
        </P>{' '}
        <P>
          The average price of core PMS in 2023 is 9 euros per room. For  9 euros per room, hoteliers usually get just a
          back office without any additional functionalities and integrations. However, you can save a lot of money by
          implementing a cloud-based solution with an integrated web booking engine and channel manager. In this case,
          you will get a discount on must-have integrations, which are critical nowadays for every hotel business.
        </P>
        <P>
          <a href="https://rentl.io/en/pricing">Calculate how much you would save by switching to Rentlio PMS.</a>
        </P>
        <H3>Implementation</H3>
        <P>
          Compared to on-prem systems, implementation of a cloud-based PMS can take up to a few days. The implementation
          cost depends from provider to provider, but most cloud-based implementations are usually free. Your hotel will
          need to spend less on maintenance and high IT costs, which are mandatory for legacy providers.
        </P>
        <H3>Onboarding</H3>
        <P>
          Hoteliers are looking for an experience that’s as close to ‘plug and play’ as possible. Many PMS providers
          offer onboarding and training programs for all hotel staff. Detail onboarding helps hotel staff and management
          learn how to use key modules and utilize features that are not part of core PMS but can make a big difference.
        </P>
        <P>
          <a href="https://app.rentl.io/en/sign-up">Sing up and try Rentlio PMS for free.</a>
        </P>
        <H3>Support</H3>
        <P>
          Having support available 24/7 is one of the most (if not the most) important aspects before selecting your PMS
          partner. Ask for specific support numbers such as working hours, how long support takes to reply, the average
          ticket resolution time, etc.
        </P>
        <P>This data will help you determine if something goes wrong and how long it takes to resolve the issue.</P>
        <H3>Key Takeaways</H3>
        <PUL>
          <ul style={{ listStyleType: 'disc' }}>
            <li>Price varies based on many factors</li>
            <li>Always look for a user-friendly cloud-based PMS</li>
            <li>Make sure you get an integrated Booking Engine and Channel Manager</li>
            <li>Look for ways to decrease IT and maintenance cost</li>
            <li>Choose the ‘plug and play’ option</li>
            <li>Make sure your staff knows how to use the software</li>
            <li>Don’t buy PMS without support</li>
          </ul>
        </PUL>
        {/* <FullWidthImg>
          <MiddleContent>
            <GradientText>99%</GradientText>
            <SideText>korisnika Rentlija ocjenjuje rad korisničke podrške s "Amazing"</SideText>
          </MiddleContent>
        </FullWidthImg> */}
        <GreyBlock>
          <H2 id="8" className="sections">
            How to Choose a Property Management System for Your Hotel Business?
          </H2>
          <P>
            Here are some of the questions to ask property management system providers regardless of the size of your
            property.
          </P>
          <PUL className="greyedUL">
            <ul style={{ listStyleType: 'disc' }}>
              <li>Is the PMS ‘’ONLY’’ Cloud or a Cloud Native Solution? Big difference!</li>
              <li>Do they offer comprehensive back office features and functionalities?</li>
              <li>
                What does the Calendar view look like? Do they offer an interactive calendar with many split options?
              </li>
              <li>Is the interface user-friendly?</li>
              <li>Can you use PMS anytime across any device?</li>
              <li>How many integrations do they offer?</li>
              <li>Can they offer comprehensive reports and statistics?</li>
              <li>Do they offer an integrated Channel Manager and Web Booking Engine?</li>
              <li>Is in-depth onboarding and support available?</li>
              <li>How long does it take to implement PMS?</li>
              <li>Is there a free trial period?</li>
            </ul>
          </PUL>
          <P>
            <a href="https://rentl.io/en/contact">
              This is not an exhaustive list, but it’s a good place to start! Why not request a Rentlio demo to see if
              it’s right for you?
            </a>
          </P>
        </GreyBlock>
        <H3 id="9" className="sections">
          Why Rentlio PMS (property management system)?
        </H3>
        <P>
          Rentlio is a modern, cloud-based property management system for hotels of all sizes. More than 1,000 customers
          in over 40 countries manage their properties using Rentlio PMS.
        </P>
        <P>
          Rentlio was founded in 2015 in Zadar, Croatia, to replace old-fashioned and outdated hotel technology by
          simplifying and automating hotel processes. At Rentlio, hotel management and operation should be easy and
          smooth, allowing hoteliers to focus on what they do best – making guests happy.
        </P>
        <P>
          <a href="https://rentl.io/en">Find out more about Rentlio PMS and start using future PMS, today!</a>
        </P>
        <ButtonCTA onClick={(e) => handleClick(e)}>Get Free Consultations</ButtonCTA>
        {/* <UnderButtonText>Počnite koristiti svoj budući PMS, već danas!</UnderButtonText> */}
      </MiddleTextContent>
    </WrapContent>
  )
}

export default Content
