import React, { useState, useEffect, useRef } from 'react'

import {
  WrapBanner,
  Content,
  TopTitle,
  Title,
  Text,
  Button,
  BannerTextBlock,
  BannerImageBlock,
  BannerImage1,
  BannerImage2,
  BannerImage3
} from './styles'
import Img from 'gatsby-image'

import bannerImg1 from '../../../assets/images/bannerImg1.png'
import bannerImg2 from '../../../assets/images/bannerImg2.png'
import bannerImg3 from '../../../assets/images/bannerImg3.png'

const Banner = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://app.rentl.io/en/sign-up'
  }
  return (
    <WrapBanner>
      <Content>
        <BannerTextBlock>
          <TopTitle>Hotel Property Management System’</TopTitle>
          <Title>Integrated PMS, Channel Manager, and Booking Engine</Title>
          <Text>
            PMS stands for a property management system, and hotel staff and management use it to run the entire
            property from pre-booking to check-out and beyond.
          </Text>
          <Button onClick={(e) => handleClick(e)}>Get a Free Consultation</Button>
        </BannerTextBlock>
        <BannerImageBlock>
          <BannerImage1>
            <img src={bannerImg1} />
          </BannerImage1>
          <BannerImage2>
            <img src={bannerImg2} />
          </BannerImage2>
          <BannerImage3>
            <img src={bannerImg3} />
          </BannerImage3>
        </BannerImageBlock>
      </Content>
    </WrapBanner>
  )
}

export default Banner
