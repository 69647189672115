import styled from 'styled-components'

import accordionArrow from '../../../images/accordion-arrow.svg'

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 2rem 2.85rem;
  background: ${({ theme }) => theme.colors.pureWhite};

  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 3.5rem;

  &::before {
    content: '';
    position: absolute;
    right: 40px;
    top: 55px;
    width: 16px;
    height: 16px;
    background: url(${accordionArrow}) no-repeat;
    background-size: 16px 8px;
    background-position: center;
    transform: ${({ isExpanded }) => (isExpanded ? `rotate(180deg)` : `rotate(0deg)`)};
    transform-origin: center;
    transition: transform 0.5s;
  }

  @media screen and (max-width: 1024px) {
    padding: 1rem 2.85rem 1rem 1.5rem;
    &::before {
      top: 32px;
    }
  }
`
export const AccordionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};

  font-family: 'Barlow';
  font-size: 1.42rem;
  font-weight: 500;
  line-height: 2.14rem;
  width: 75%;
  margin: 1rem 0;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
`
export const AccordionContent = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};

  line-height: 1.71rem;
  max-height: ${(props) => props.height};
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;

  p {
    margin-bottom: 1rem;
    padding-right: 1rem;
  }

  a {
    color: ${({ theme }) => theme.colors.vividBlue};

    &:hover {
      text-decoration: underline;
    }
  }
`
